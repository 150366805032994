<template>
  <v-container fluid class="ma-0 pa-0">
    <v-toolbar flat color="toolbar">
      <v-breadcrumbs large :items="breadcrumbs">
        <template #item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled">
            <span :class="!item.disabled ? 'primary--text' : ''">
              {{ item.text }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-divider vertical inset class="mx-4" />
      <v-row align="center" class="flex-nowrap" dense>
        <v-col align="start" class="flex-nowrap flex-shrink-1" >
          <span class="text-subtitle-1">
            <span class="font-weight-medium">
              Ad Dates:
            </span>
            <span style="margin-left: 2px">
              {{ formatDateDisplay(adPlanner.ad_start_date) }} - {{ formatDateDisplay(adPlanner.ad_end_date) }}
            </span>
          </span>
        </v-col>
        <v-col v-if="getsSuggestedOrders" cols="2">
            <SuggestedOrderStatus
              flex-shrink-1
              :adPlanner="adPlanner"
              :store_id="store_id"
              :orderInfo="orderingInfo"
              :isManager="isEdgeManager"
              @updated="updateStatus">
            </SuggestedOrderStatus>
        </v-col>
        <v-col v-if="getsSuggestedOrders" cols="5" class="flex-nowrap">
          <v-btn
            height="38"
            width="20"
            flex-shrink-1
            :disabled="checkedItems.length < 1"
            class="amber darken-3 white--text text-capitalize mx-1 flex-nowrap"
            @click.stop="barChart = true">
            <v-icon>mdi-chart-timeline-variant</v-icon>
          </v-btn>
          <v-btn
            height="38"
            flex-shrink-1
            class="mx-1 flex-nowrap"
            :disabled="!resetToOriginalSelectionActive"
            color="error"
            @click="showResetSuggestedOrderModal = true">
            Reset Suggested Grid
          </v-btn>
          <v-btn
            height="38"
            class="mx-1 flex-nowrap"
            flex-shrink-1
            @click="showExportModal=true">
            <span>
              <v-icon>mdi-file-excel-outline</v-icon>
              Exports
            </span>
          </v-btn>
        </v-col>
        <v-col class="mx-1 d-flex ml-auto" cols="3">
          <SearchFilters :departments="departments" :pages="pages" v-model="searchParams"/>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :height="tableSize"
      :items-per-page="pageSize"
      :items.sync="groupedItems"
      :item-class="rowHighlight"
      :loading="loading"
      v-model="checkedItems"
      ref="my-table"
      :group-by="getsSuggestedOrders ? 'comboKey' : null"
      sort-by="['page_name', 'position_name']"
      dense
      fixed-header
      hide-default-footer
      loading-text="Loading... Please Wait"
      no-data-text="No Retail Ad Items to Display"
      no-results-text="No Matches Found"
      item-key="ad_plan_id"
      :expanded.sync="expanded"
      :show-select="getsSuggestedOrders"
      @click:row="highlighted=$event">

      <!-- <template #[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length" class="primary white--text pl-4" style="border: 1px solid grey !important;">
          <v-btn color="white" tabindex="-1" dense @click.stop="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
          {{ group }}
        </td>
      </template> -->
      <!-- formatting the headers -->
      <template #[`item.components`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn v-on="on"
              v-if="item.is_shipper"
              icon color="primary" @click.stop="showComponentsModal(item)">
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </template>
          <span>Components</span>
        </v-tooltip>
      </template>

      <template #expanded-item="{ headers, item }">
        <td
          @click="highlighted=item"
          :colspan="headers.length"
          class="expandedRow"
          :class="item.item_id === highlighted.item_id ? 'primary white--text' : ''"
          style="cursor:pointer">
        <v-row v-if="items.length && showDeliveryDates" dense>
          <v-col cols="1" style="height:65px;" class="pt-1 mb-3"
            v-for="order in item.item_orders" :key="order.item_id">
            <v-text-field
              :disabled="item.is_dsd"
              :readonly="order.processed || orderingClosed(item, order)"
              outlined
              solo
              :background-color="order.processed || orderingClosed(item, order) ? 'grey lighten-3' : 'white'"
              :placeholder="orderingClosed(item, order) ? 'Closed' : 'Quantity'"
              :hint="getHint(order, item)"
              persistent-hint
              class="mt-2"
              dense
              type="number"
              :color="order.processed || orderingClosed(item, order) ? 'grey darken-1' : 'primary'"
              :label="moment(order.delivery_date).format('ddd_MM/DD')"
              @focus="activeInput=order"
              @change="setOrder(item, order, $event)"
              :value="order.quantity > 0 ? order.quantity : null"
              min="1">
              <template #prepend-inner>
                <v-tooltip v-if="!disableSuggestedTip(order, item)" top>
                  <template #activator="{ on }">
                    <v-btn small v-on="on" icon @click="setOrder(item, order, order.suggested_qty)">
                      <v-icon color="green">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Apply Suggested Order</span>
                </v-tooltip>
              </template>
              <template #append>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon v-on="on" :color="order.processed ? 'green' : orderingClosed(item, order) ? 'red' : ''">
                      {{order.processed ? 'mdi-checkbox-marked-circle-outline' : ''}}
                    </v-icon>
                  </template>
                  <span>{{order.processed ? 'Order Processed' : orderingClosed(item, order) ? 'Ordering Closed' : ''}}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="1" class="ml-4 mt-5 d-flex justify-center">
            <v-badge
              class="font-weight-medium"
              color="green"
              :value="item.total_ordered >= 1"
              :content="item.total_ordered">
              <v-icon :color="item.item_id === highlighted.item_id ? 'white' : ''">mdi-cart-outline</v-icon>
            </v-badge>
            <template v-if="getsSuggestedOrders">
              <v-tooltip v-if="!orderingClosed(item)" top>
                <template #activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    class="ml-4"
                    :disabled="item.total_ordered < 1"
                    @click="clearItemOrders(item)">
                    <v-icon color="red">mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>Clear Cart</span>
              </v-tooltip>
              <v-tooltip v-if="!orderingClosed(item)" top>
                <template #activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      :class="{
                        'grey--text': !getOrderChanged(item),
                        'icon-circle': getOrderChanged(item),
                        'icon-circle-active': getOrderChanged(item) && item.item_id === highlighted.item_id
                      }"
                      :disabled="!getOrderChanged(item)"
                      @click="resetSuggestedOrder(item.item_id)">
                      <v-icon size="19">mdi-reload</v-icon>
                    </v-btn>
                </template>
                <span>Reset To Originally Suggested Orders</span>
              </v-tooltip>
            </template>
          </v-col>
          <v-col cols="2" class="ma-2">
            <span>
              <b class="pr-2">AMAP Dates:</b>
              {{
                item.amap_start_date && item.amap_end_date ?
                  `${formatDateDisplay(item.amap_start_date)} - ${formatDateDisplay(item.amap_end_date)}` :
                    '---'
              }}
            </span>
            <br>
            <span>
              <b class="pr-2">TPR Dates:</b>
              {{
                item.tpr_start_date && item.tpr_end_date ?
                `${formatDateDisplay(item.tpr_start_date)} - ${formatDateDisplay(item.tpr_end_date)}` :
                  '---'
              }}
            </span>
          </v-col>
          <v-col cols="1" align="center">
            <v-btn
              height="38"
              v-if="item.is_shipper"
              text
              :color="item.item_id === highlighted.item_id ? 'white' : 'primary'" @click.stop="showComponentsModal(item)"
              class="text-capitalize"
              style="margin-top:10px">
              <v-icon left>mdi-magnify</v-icon>
              Components
            </v-btn>
          </v-col>
        </v-row>
        </td>
      </template>

      <template #[`item.last_90_days_shipped_qty`]="{ item }">
        <span class="noWrap">{{ item.last_90_days_shipped_qty ? item.last_90_days_shipped_qty : '---' }}</span>
      </template>

      <template #[`item.tpr_dates`]="{ item }">
        <span class="noWrap">
          {{
            item.tpr_start_date && item.tpr_end_date
            ?`${formatDateDisplay(item.tpr_start_date)} - ${formatDateDisplay(item.tpr_end_date)}`
            : '---'
          }}
          </span>
      </template>

      <template #[`item.department`]="{ item }">
        <span class="noWrap">{{ item.department }}</span>
      </template>

      <template #[`item.description`]="{ item }">
        <span class="noWrap">{{ item.description }}</span>
      </template>

      <template #[`item.upc`]="{ item }">
        <span class="noWrap">{{ item.upc ? item.upc : '---' }}</span>
      </template>

      <template #[`item.case_cost`]="{ item }">
        <span class="noWrap">
          ${{item.case_cost | format}}
        </span>
      </template>

      <template #[`item.amap`]="{ item }">
        <span class="noWrap">
          ${{ item.amap | format}}
        </span>
      </template>

      <template #[`item.early_buy_billback`]="{ item }">
        <span class="noWrap">
          ${{ item.early_buy_billback | format}}
        </span>
      </template>

      <template #[`item.ad_billback`]="{ item }">
        <span class="noWrap">
          ${{ item.ad_billback | format}}
        </span>
      </template>

      <template #[`item.tpr_billback`]="{ item }">
        <span class="noWrap">
          ${{ item.tpr_billback | format}}
        </span>
      </template>

      <template #[`item.edlc_billback`]="{ item }">
        <span class="noWrap">
          ${{ item.edlc_billback | format }}
        </span>
      </template>

      <template #[`item.ad_scan`]="{ item }">
        <span class="noWrap">
          ${{ item.ad_scan | format}}
        </span>
      </template>

      <template #[`item.tpr_scan`]="{ item }">
        <span class="noWrap">
          ${{ item.tpr_scan | format}}
        </span>
      </template>

      <template #[`item.edlc_scan`]="{ item }">
        <span class="noWrap">
          ${{ item.edlc_scan | format }}
        </span>
      </template>

      <template #[`item.net_unit_cost`]="{ item }">
        <div style="display: flex; align-items: center;">
          <span>${{ item.net_unit_cost | format}}</span>
          <v-menu
            v-if="tenant === 'alliance-retail-group' && item.deal_cost_dollar_savings != null && item.deal_cost_percent_savings != null"
            open-on-hover
            offset-y
            top
            :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-btn
                class="pa-0 ma-0"
                v-on="on"
                small
                icon
              >
                <v-icon class="ma-0 pa-0">mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <div>
                    <span style="font-weight: bold;">Dollar Savings:</span> 
                    <span style="font-weight: bold; color: #007bff;"> ${{item.deal_cost_dollar_savings}}</span>
                </div>
                <div>
                    <span style="font-weight: bold;">Percent Savings:</span> 
                    <span style="font-weight: bold; color: #007bff;"> {{item.deal_cost_percent_savings}}%</span>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </template>

      <template #[`item.unit_cost`]="{ item }">
        <span class="noWrap">
          ${{ item.unit_cost | format}}
        </span>
      </template>

      <template #[`item.ad_retail`]="{ item }">
        <span class="noWrap">
          {{item.ad_retail_count}}/${{item.ad_retail | format}}
        </span>
      </template>

      <template #[`item.ad_gross_profit_percent`]="{ item }">
        <span class="noWrap">
          {{ item.ad_gross_profit_percent | format}}%
        </span>
      </template>

      <template #[`group.header`]="{ group, headers, items, isOpen, toggle }"
      v-if="getsSuggestedOrders">
        <td
          :colspan="headers.length"
          style="
            height: 40px;
            border-top: 1px solid grey !important;
          "
          class="blue-grey lighten-2 white--text">
          <v-row dense align="center">
            <v-btn
              dark
              @click="toggle"
              small
              icon
              :ref="group"
              :data-open="isOpen">
              <v-icon>
                {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
            <span class="ml-1 font-weight-medium">
              {{ group }}
            </span>
            <v-divider inset vertical length="10" class="mx-4 mb-1 white" />
            <v-tooltip color="main" top>
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :value="items.some(({ feature_note }) => feature_note)"
                  right
                  color="amber darken-2"
                  overlap
                  dot
                  offset-x="15"
                  offset-y="15">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="openNote(items, group)">
                    <v-icon color="white">mdi-note-text-outline</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <span> Notes </span>
            </v-tooltip>
          </v-row>
        </td>
      </template>

      <template v-slot:footer>
        <v-divider></v-divider>
        <v-toolbar flat color="toolbar">
          <v-spacer></v-spacer>
          <v-col cols="2" style="max-width: 150px">
            <v-select class="mt-8" dense :items="pageSizes" label="Items Per Page" v-model="pageSize"
              :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
            </v-select>
          </v-col>
          <v-btn icon large @click="previous" :disabled="disablePrevious" class="mx-2">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <small>Page {{ page }}</small>
          <v-btn icon large @click="next" :disabled="disableNext" class="mx-2">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog :value.sync="dialog">
      <v-card>
        <v-card-title>
          <v-icon size="35" class="red--text mr-3">mdi-alert-octagon</v-icon>
          <span class="font-weight-light">Unable to Proceed</span>
        </v-card-title>
        <v-card-text>
          No order delivery days are assigned to store.
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text class="text-capitalize font-weight-medium" :to="{ path: '/retail-manager' }">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ComponentItems
      v-if="adPlanner && showComponents"
      v-model="showComponents"
      :ad_id="adPlanner.ad_id"
      :selectedItem="selectedItem"
      @close="showComponents = false">
    </ComponentItems>
    <ChartModal
      :value.sync="barChart"
      :selected="checkedItems"
      :adPlanner="adPlanner"
      :meat_grid="adPlanner.meat_grid"
      :store_id="store_id"
      :storeParties="storeParties"
      :storeDeliveryDays="storeDeliveryDays"
      :party_id="store_id"
      @confirmUpdate="confirmUpdate"
      @applySelectedHistoricalSuggestedOrders="applySelectedHistoricalSuggestedOrders"
    />
    <ExportModal
      v-if="showExportModal"
      :value.sync="showExportModal"
      :ad_id="adPlanner.ad_id"
      :ad_name="adPlanner.ad_name"
      :meat_grid="adPlanner.meat_grid"
      :storeDeliveryDays="storeDeliveryDays"
      :store_id="store_id"
      :storeParties="storeParties"
      @close="closeModal"
    />
    <ResetSuggestedOrdersModal
      v-if="showResetSuggestedOrderModal"
      :value.sync="showResetSuggestedOrderModal"
      @reset="resetSuggestedOrder"
    />

    <FeatureNote :value.sync="showNote" :item="selectedItem"/>
  </v-container>
</template>

<script>
// api
import AdRetails from "@/axios/ad-retails"
import Attributes from "@/axios/attributes"
import ItemOrder from '@/axios/item_order'
// mixins
import { displayAlert } from "@/mixins/alert"
import { fullwidth } from "@/mixins/fullwidth"
import { paging } from "@/mixins/paging"
import { displayHelpers } from "@/mixins/displayHelpers"
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
// third-party
import _ from 'lodash'
import moment from 'moment'
// components

const SearchFilters = () => import('@/components/SearchFilters.vue')
const ComponentItems = () => import('@/components/ComponentItems.vue')
const SuggestedOrderStatus = () => import('@/components/SuggestedOrderStatus.vue')
const ChartModal = () => import('@/components/charts/ChartModal')
const ExportModal = () => import('@/components/ExportModal.vue')
const ResetSuggestedOrdersModal = () => import('@/components/ResetSuggestedOrdersModal.vue')
const FeatureNote = () => import('@/components/FeatureNote.vue')

export default {
  name: "RetailAdItems",

  components: { SearchFilters, ComponentItems, SuggestedOrderStatus, ChartModal, ExportModal, FeatureNote, ResetSuggestedOrdersModal },

  mixins: [displayAlert, fullwidth, displayHelpers, paging, userAccess, utils],

  data() {
    return {
      adPlanId: 0,
      adPlanner: {},
      adPlannerParty: {},
      barChart: false,
      checkedItems: [],
      component: '',
      departments: [],
      dialog: false,
      expanded: [],
      filters: [],
      highlighted: {},
      items: [],
      loading: true,
      orderItem: null,
      orders: [],
      showExportModal: false,
      pages: [],
      searchParams: {},
      selected: [],
      selectedItem: {},
      shipperMod: {},
      showComponents: false,
      showResetSuggestedOrderModal: false,
      filterByShipperMods: false,
      showDeliveryDates: true,
      store_id: null,
      store_name: null,
      storeDeliveryDays: [],
      tableHeight: "",
      processedOrders: [],
      suggestedAmountsExist: false,
      isEdgeSubscriber: null,
      storeParties: [],
      note: null,
      showNote: false
    }
  },

  watch: {
    adPlanner: {
      handler(newValue) {
        if (newValue) {
          sessionStorage.setItem("ad_planner", JSON.stringify(this.adPlanner))
        }
      },
      deep: true
    },

    adPlannerParty: {
      handler(newValue) {
        if (newValue) {
          sessionStorage.setItem("ad_planner_party", JSON.stringify(this.adPlannerParty))
        }
      },
      deep: true
    },

    storeParties: {
      handler(newValue) {
        if (newValue) {
          sessionStorage.setItem("store_parties", JSON.stringify(this.storeParties))
        }
      },
      deep: true
    },

    itemFilters: {
      handler: _.debounce(function (newValue) {
        this.items = []
        this.loading = true
        if (newValue) {
          if (this.page > 1) {
            this.page = 1
          } else {
            this.getOrderGridData()
          }
        }
      }, 500),
      deep: true
    }
  },

  async created () {
    this.nonTableHeight = 224
    // Route Params
    const { adPlanner, party, store_id, store_name, edge_sub, storeParties } = this.$route.params
    this.adPlanner = adPlanner || JSON.parse(sessionStorage.getItem("ad_planner"))
    this.adPlannerParty = party || JSON.parse(sessionStorage.getItem("ad_planner_party"))
    this.store_id = store_id || sessionStorage.getItem("store_id")
    this.store_name = store_name || sessionStorage.getItem("store_name")
    this.isEdgeSubscriber = edge_sub || JSON.parse(sessionStorage.getItem("edge_subscriber"))
    this.storeParties = storeParties || JSON.parse(sessionStorage.getItem("store_parties"))

    this.getOrderGridData()
    this.tableChanged = this.tableChange(this.getOrderGridData)
    this.getDepartments()
    this.getPages()

    this.storeDeliveryDays = await this.getStoreDeliveryDays()
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: this.adPlannerParty.name,
          exact: true,
          href: '/retail-manager'
        },
        {
          text: this.adPlanner.ad_name,
          exact: true,
          disabled: true
        },
        {
          text: this.store_name,
          exact: true,
          disabled: true
        }
      ]
    },

    storeDeliveryDates () {
      const startDate = moment(this.adPlanner.ad_start_date).subtract(1, 'weeks')
      const endDate = moment(this.adPlanner.ad_start_date).add(6, 'days')
      const now = startDate.clone()
      const days = []
      while (now.isSameOrBefore(endDate)) {
        days.push({ date: now.format('YYYY-MM-DD'), quantity: '' })
        now.add(1, 'days')
      }
      return days.filter(day => this.storeDeliveryDays.includes(moment(day.date).day()))
    },

    totalItems () {
      return this.items.length
    },

    disablePrevious () {
      return (this.page - 1) * this.pageSize === 0
    },

    disableNext () {
      return this.totalItems < this.pageSize
    },

    headers () {
      if (this.getsSuggestedOrders) {
        return [
          { sortable: false, value: 'data-table-select', class: 'accent white--text', show: true },
          { text: 'Department', sortable: true, filterable: true, class: 'accent white--text', value: 'department' },
          { text: 'Item No', sortable: true, filterable: true, class: 'accent white--text', value: 'item_id' },
          { text: 'UPC', sortable: true, filterable: true, class: 'accent white--text', value: 'upc' },
          { text: 'Description', sortable: true, filterable: true, class: 'accent white--text', value: 'item_desc' },
          { text: 'Pack', sortable: true, filterable: true, class: 'accent white--text', value: 'qty_per_case' },
          { text: 'Size', sortable: true, filterable: true, class: 'accent white--text', value: 'pack_size' },
          { text: 'Case Cost', width: '105', sortable: true, filterable: true, class: 'accent white--text', value: 'case_cost' },
          { text: 'Unit Cost', sortable: true, filterable: true, class: 'accent white--text', value: 'unit_cost' },
          { text: 'AMAP', sortable: true, filterable: true, class: 'accent white--text', value: 'amap' },
          { text: 'EBA', sortable: true, filterable: true, class: 'accent white--text', value: 'early_buy_billback' },
          { text: 'Ad BB', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_billback' },
          { text: 'TPR BB', sortable: true, filterable: true, class: 'accent white--text', value: 'tpr_billback' },
          { text: 'EDLC BB', sortable: true, filterable: true, class: 'accent white--text', value: 'edlc_billback' },
          { text: 'Ad Scan', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_scan' },
          { text: 'TPR Scan', sortable: true, filterable: true, class: 'accent white--text', value: 'tpr_scan' },
          { text: 'EDLC Scan', sortable: true, filterable: true, class: 'accent white--text', value: 'edlc_scan' },
          { text: 'Net Unit Cost', sortable: true, filterable: true, class: 'accent white--text', value: 'net_unit_cost' },
          { text: 'Ad SRP', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_retail' },
          { text: 'Ad Gross Profit %', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_gross_profit_percent' },
          { text: 'Avg. Movement', sortable: true, filterable: true, class: 'accent white--text', value: 'last_90_days_shipped_qty' }
          // { text: 'Components', align: 'center', filterable: false, sortable: false, class: 'accent white--text', value: 'components' }
        ]
      } else {
        return [
          { text: 'Department', sortable: true, filterable: true, class: 'accent white--text', value: 'department' },
          { text: 'Item No', sortable: true, filterable: true, class: 'accent white--text', value: 'item_id' },
          { text: 'UPC', sortable: true, filterable: true, class: 'accent white--text', value: 'upc' },
          { text: 'Description', sortable: true, filterable: true, class: 'accent white--text', value: 'item_desc' },
          { text: 'Pack', sortable: true, filterable: true, class: 'accent white--text', value: 'qty_per_case' },
          { text: 'Size', sortable: true, filterable: true, class: 'accent white--text', value: 'pack_size' },
          { text: 'Case Cost', width: '105', sortable: true, filterable: true, class: 'accent white--text', value: 'case_cost' },
          { text: 'Unit Cost', sortable: true, filterable: true, class: 'accent white--text', value: 'unit_cost' },
          { text: 'AMAP', sortable: true, filterable: true, class: 'accent white--text', value: 'amap' },
          { text: 'EBA', sortable: true, filterable: true, class: 'accent white--text', value: 'early_buy_billback' },
          { text: 'Ad BB', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_billback' },
          { text: 'TPR BB', sortable: true, filterable: true, class: 'accent white--text', value: 'tpr_billback' },
          { text: 'EDLC BB', sortable: true, filterable: true, class: 'accent white--text', value: 'edlc_billback' },
          { text: 'Ad Scan', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_scan' },
          { text: 'TPR Scan', sortable: true, filterable: true, class: 'accent white--text', value: 'tpr_scan' },
          { text: 'EDLC Scan', sortable: true, filterable: true, class: 'accent white--text', value: 'edlc_scan' },
          { text: 'Net Unit Cost', sortable: true, filterable: true, class: 'accent white--text', value: 'net_unit_cost' },
          { text: 'Ad SRP', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_retail' },
          { text: 'Ad Gross Profit %', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_gross_profit_percent' },
          { text: 'Avg. Movement', sortable: true, filterable: true, class: 'accent white--text', value: 'last_90_days_shipped_qty' }
          // { text: 'Components', align: 'center', filterable: false, sortable: false, class: 'accent white--text', value: 'components' }
        ]
      }
    },

    orderingInfo() {
      const adStartDate = moment(this.adPlanner.ad_start_date)
      const currentDate = moment().format(this.$dateConfig.date_format)
      let orderInfo = {
        currentDate: currentDate,
        lastOrderDate: moment(adStartDate).subtract(4, 'weeks').format(this.$dateConfig.date_format),
        lastShipperOrderDate: moment(adStartDate).subtract(5, 'weeks').format(this.$dateConfig.date_format)
      }
      if (this.tenant === "pricechopper") {
        orderInfo = {
          ...orderInfo,
          lastOrderDate: moment(adStartDate).subtract(5, 'weeks').subtract(2, 'days').format(this.$dateConfig.date_format),
          lastShipperOrderDate: moment(adStartDate).subtract(5, 'weeks').subtract(2, 'days').format(this.$dateConfig.date_format)
        }
      }
      return orderInfo
    },

    itemFilters () {
      return { ...this.searchParams }
    },

    groupedItems() {
      return this.items.map(item => {
        const comboKey = `${item.page_name} | ${item.position_name} | ${item.feature_text}`
        return {
          ...item,
          comboKey
        }
      })
    },

    getsSuggestedOrders() {
      return (this.tenant === 'alliance-retail-group' && this.isEdgeSubscriber)
    },
    
    resetToOriginalSelectionActive() {
      for (const item of this.items) {
        if (!this.orderingClosed(item) && this.getOrderChanged(item)) {
          return true
        }
      }
      return false
    }
  },

  methods: {
    rowHighlight(item) {
      return item.item_id === this.highlighted.item_id ? 'primary white--text' : ''
    },

    async confirmUpdate(options) {
      this.options = { ...options }
    },

    showComponentsModal(item) {
      this.selectedItem = item
      this.showComponents = true
    },

    orderingClosed(item) {
      const isShipperItem = item.is_shipper
      const { currentDate, lastOrderDate, lastShipperOrderDate } = this.orderingInfo

      let isDraft = true
      if (this.getsSuggestedOrders) {
        isDraft = this.adPlanner.suggested_order_status === 'DRAFT'
      }
      return (isShipperItem && currentDate > lastShipperOrderDate) || (currentDate > lastOrderDate) || !isDraft
    },

    async getStoreDeliveryDays() {
      try {
        const { data } = await Attributes.getStoreDeliveryDays(
          this.store_id,
          100,
          0
        )
        const storeDeliveryDays = data.reduce((acc, key) => {
          if (key.attr.id === "STORE_DELIVERY_DAYS") {
            acc.push(
              moment().day(key.attr_key_value.name).format("dddd").toLowerCase()
            )
          }
          return acc
        }, [])

        if (storeDeliveryDays.length > 0) {
          return storeDeliveryDays
        } else {
          this.dialog = true
        }
      } catch (err) {
        this.handleError(err)
      }
    },

    addDealSavings() {
      this.items = this.items.map(item => {
        if (item.unit_cost && item.net_unit_cost) {
          const dollarSavings = item.unit_cost - item.net_unit_cost;
          const percentSavings = (dollarSavings / item.unit_cost) * 100;

          return {
            ...item,
            deal_cost_dollar_savings: dollarSavings.toFixed(2),
            deal_cost_percent_savings: percentSavings.toFixed(2)
          };
        }

        return {
          ...item,
          deal_cost_dollar_savings: null,
          deal_cost_percent_savings: null
        };
      });
    },

    async getOrderGridData() {
      let items = []
      const storeDeliveryDays = await this.getStoreDeliveryDays()
      const params = this.buildPromoListParams()
      try {
        const { data } = await AdRetails.getOrderGridData(
          params,
          this.store_id,
          this.adPlanner.ad_id,
          this.getsSuggestedOrders,
          this.adPlanner.meat_grid,
          storeDeliveryDays,
          this.from,
          this.pageSize
        )
        if (data?.length > 0) {
          items = data.flatMap(item => {
            item.is_dsd = item.item_id.startsWith('D', 0)
            return this.getItemTotalOrdered(item)
          })
          this.items = items
          this.expanded = this.items
          this.addDealSavings();
        } else {
          this.items = []
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
        this.checkAdHasSuggestedOrders()
      }
    },

    buildPromoListParams() {
      let params = {}
      if (Object.entries(this.itemFilters).length > 0) {
        params = { ...this.itemFilters }
      }
      return params
    },

    getItemTotalOrdered(item) {
      const totalOrdered = item.item_orders.reduce((total, order) => total + (order.quantity || 0), 0)
      item.total_ordered = totalOrdered
      return item
    },

    setOrder (item, order, quantity) {
      if (quantity == null) {
        return
      }
      if (!_.isEmpty(order.order_id) && quantity) {
        if (order.quantity !== quantity) {
          this.updateOrder(item, order, quantity)
        }
      } else if (!_.isEmpty(order.order_id) && quantity === '') {
        this.deleteOrder(order.order_id)
      } else {
        this.createOrder(item, order, quantity)
      }
    },

    getOrderChanged(item) {
      for (const order of item.item_orders) {
        if (!order.processed && order.quantity >= 0 && order.quantity !== order.suggested_qty) {
          return true
        }
      }
      return false
    },

    buildRequestBody(item, order, quantity) {
      const payload = {
        party_id: this.store_id,
        ad_id: this.adPlanner.ad_id,
        feature_id: item.feature_id,
        item_id: item.item_id,
        quantity: quantity,
        expected_delivery_date: order.delivery_date
      }
      if (this.getsSuggestedOrders) {
        payload.order_batch_id = item.suggested_batch_id || this.adPlanner.suggested_order_grid_id
      }
      return payload
    },

    async createOrder (item, order, quantity) {
      const requestBody = this.buildRequestBody(item, order, quantity)
      try {
        await ItemOrder.post(requestBody, this.getsSuggestedOrders)
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.getOrderGridData()
      }
    },

    async updateOrder (item, order, quantity) {
      const requestBody = this.buildRequestBody(item, order, quantity)
      try {
        await ItemOrder.put(order.order_id, requestBody, this.getsSuggestedOrders)
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.getOrderGridData()
      }
    },

    async deleteOrder(orderId) {
      try {
        await ItemOrder.delete(orderId, this.getsSuggestedOrders)
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.getOrderGridData()
      }
    },

    async getPages() {
      try {
        const { data } = await AdRetails.getPages(this.adPlanner.ad_id)
        if (data.length > 0) {
          this.pages = data
        }
      } catch (err) {
        this.handleError(err)
      }
    },

    async getDepartments() {
      try {
        const { data } = await AdRetails.getDepartments(this.adPlanner.ad_id)
        if (data.length > 0) {
          this.departments = data
        }
      } catch (err) {
        this.handleError(err)
      }
    },

    async clearItemOrders(item) {
      const promises = item.item_orders.map(order => {
        if (order.order_id !== "") {
          order.quantity = 0
          return ItemOrder.delete(order.order_id, this.getsSuggestedOrders)
        }
      })

      try {
        this.loading = true
        const { rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.getOrderGridData()
        this.loading = false
      }
    },

    checkAdHasSuggestedOrders() {
      this.suggestedAmountsExist = false

      for (const item of this.items) {
        if (this.itemHasSuggestedOrders(item)) {
          this.suggestedAmountsExist = true
        }
      }
    },

    itemHasSuggestedOrders(item) {
      // Loop through all the orders of the item, and return true ANY are flagged to display the suggested order tip
      for (const order of item.item_orders) {
        if (!this.disableSuggestedTip(order, item)) {
          return true
        }
      }

      return false
    },

    disableSuggestedTip(order, item) {
      if (this.orderingClosed(item)) { return true }

      if (order.suggested_qty) {
        return order.quantity > 0
      }
      return true
    },

    getHint(order, item) {
      if (this.disableSuggestedTip(order, item)) { return "" }

      return "Suggested Order: " + order.suggested_qty
    },

    async applyItemLevelSuggestedOrders(item) {
      const promises = item.item_orders.map(order => {
        if (order.suggested_qty && order.suggested_qty > 0) {
          const payload = this.buildRequestBody(item, order, order.suggested_qty)
          order.quantity = order.suggested_qty
          return ItemOrder.post(payload, this.getsSuggestedOrders)
        }
      })

      try {
        this.loading = true
        const { rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.getOrderGridData()
        this.loading = false
      }
    },

    async resetSuggestedOrder(item_id = null) {
      this.showResetSuggestedOrderModal = false;
      try {
        this.loading = true
        ItemOrder.resetSuggestedOrder(this.adPlanner.ad_id, this.store_id, this.adPlanner.suggested_order_grid_id, item_id, this.adPlanner.meat_grid)
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.getOrderGridData()
        this.loading = false
      }
      this.setNextFn()
    },

    async applySelectedHistoricalSuggestedOrders() {
      try {
        this.loading = true
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.getOrderGridData()
        this.loading = false
      }
      this.emitAlert(true, 'success', 'Orders Generated!')
      this.setNextFn()
    },

    updateStatus(newStatus) {
      this.adPlanner.suggested_order_status = newStatus
    },

    async closeModal() {
      this.showExportModal = false
    },

    openNote(items, feature) {
      this.note = feature
      this.selectedItem = items[0]
      this.showNote = true
    }
  }
}
</script>
<style>
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #fff !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: white;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}

.text-start {
  border-top: 1px solid #9e9e9e !important;
  border-bottom: none !important;
  /* border-top: 2px solid #385f73 !important; */
}

.noWrap {
  white-space: nowrap;
}

.active {
  box-shadow: 0px 2px 10px 3px rgba(120, 120, 120, 0.75);
  background: #385f73;
  color: white !important;
}

.filtered {
  background: #ffc107 !important;
  color: white !important;
}

.icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px; 
  border-radius: 50%;
  background-color: #ff8f00; 
  color: white !important;
  border: 5px solid white; 
  box-sizing: border-box; 
}

.icon-circle-active {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px; 
  height: 40px; 
  border-radius: 50%;
  background-color: #ff8f00;
  color: white !important;
  border: 5px solid #2589bd; 
  box-sizing: border-box; 
}
</style>
